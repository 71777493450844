form {
  margin: 15px;
  width: 100%;
  gap: 10px;
  display: flex;
  justify-content: center;
  input {
    color: #757575;
    font-size: 1.05rem;
    border-radius: 10px;
    padding: 1rem 4rem;
    text-align: center;
    border: none;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
    width: 100%;
    outline: none;
  }
  input:focus {
    border: 1px solid #8e8e8e;
  }
}
