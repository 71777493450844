* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background: rgb(94, 204, 234);
  background: linear-gradient(
    254deg,
    rgba(94, 204, 234, 1) 8%,
    rgba(0, 0, 0, 1) 60%
  );
}

.bg-wrap {
  background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
  background-image: linear-gradient(to right, #141e30, #243b55);
  background-image: linear-gradient(to right, #9796f0, #fbc7d4);
  background-color: hsla(0, 0%, 0%, 1);
  height: 100vh;
  background-image: radial-gradient(
      at 7% 9%,
      hsla(28, 0%, 0%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 62% 40%, hsla(208, 71%, 16%, 1) 0px, transparent 50%),
    radial-gradient(at 33% 25%, hsla(214, 74%, 42%, 1) 0px, transparent 50%),
    radial-gradient(at 96% 96%, hsla(193, 100%, 70%, 1) 0px, transparent 50%);
}

.container {
  margin-bottom: 100px;
  position: absolute;
  top: 25vh;
  left: 50%;
  transform: translate(-50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px 0 35px;
  background-color: #fdfdfd;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.685) 0px 20px 26px -20px;
  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -59px;
  }
}

h1 {
  font-family: 'Mulish', sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-size: 3.4rem;
  background-image: linear-gradient(45deg, #7d2392, #ee994e);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

@media (max-width: 700px) {
  .container {
    width: 100%;
    top: 80px;
    height: 100vh;
    border-radius: 15px 15px 0 0;
    input {
      padding: 1rem 1rem;
    }
  }
}
