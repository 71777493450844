$completedTaskColor: #408ca1;

.todoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #707070;
  background-color: #fdfdfd;
  padding: 0.35rem;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;

  .task {
    max-width: 320px;
    font-weight: 400;
  }
  .completedTask {
    text-decoration: line-through;
    color: $completedTaskColor;
    font-weight: 400;
  }
  h6 {
    cursor: pointer;
    color: pink;
  }
  .taskWrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5px;
  }
  .todoIcon {
    color: #c5bebe;
  }
  .completedtodoIcon {
    color: $completedTaskColor;
    cursor: pointer;
  }
  .deleteIcon {
    color: #c5bebe;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .deleteIcon:hover {
    color: #d28ed4;
    transform: scale(1.1);
  }
  .buttons {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}

.todoWrapper:hover {
  background-color: #f5fafd;
}

h3 {
  font-size: 1rem;
  cursor: pointer;
}

.deleted {
  transition: opacity 0.5s ease;
  opacity: 0;
}
